/* LoginPage.css */

.login-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.login-left, .login-right {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.login-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.login-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Media query para dispositivos móveis */
@media (max-width: 768px) {
  .login-container {
      flex-direction: column;
  }

  .login-left, .login-right {
      flex: none;
      width: 100%;
      padding: 10px;
  }

  input, button {
      width: 90%;
  }
}
